<template>
  <div class="single-bridge-common">
    <div class="tit-search flex space-between">
      <div class="mb-2"></div>
      <c-search
          v-model="pageParams"
          :searchItems="searchItems"
          @search="pageGet(true)"
      ></c-search>
    </div>
    <div class="table-wrapper" >
      <c-table
          :index="false"
          :data="pageData"
          :tableColumns="tableColumns"
          :stripe="true"
          :pagination="pageParams"
          @change="pageGet"
      >
        <template v-slot:custom="{ row }">
          <span class="opt-edit" @click="gotoDetail(row.id)">详情</span>
        </template>
      </c-table>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        title="详情"
        :visible.sync="dialogVisible"
        :before-close="beforeDialogClose"
        :destroy-on-close="true"
        width="100%"
    >
      <div class="flex space-between" style="width: 100%;height: 400px">
        <div class="health-chart">
          <div class="detail">
            <span>传感器：{{chartData.sensorName}}</span>
            <span>测点：{{chartData.pointName}}</span>
            <span>视频名称：{{chartData.videoName}}</span>
            <span>报警类型：{{chartData.warnType}}</span>
          </div>
          <chart-one ref="chartOne" v-loading="loading" style="height: 400px" :value="chartData"></chart-one>
        </div>
        <div class="res-video">
          <vue-aliplayer-v2
              ref="videoPlayer"
              :source="video.url"
              :options="options"
              @ready="handleReady()"
          />
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
  import MPage from "@/mixins/MPage.js";
  import MHandle from "@/mixins/MHandle.js";
  import Configs from "./config/index";
  import chartOne from "./charts/chart01"
  import VueAliplayerV2 from "vue-aliplayer-v2";
  let mPage = MPage({
    pagePath: "/intelligentCapture/listPage/warn",
    pageDelPath: "",
    pageDelsPath: "",
    pageParams: {},
  });
  let mHandle = MHandle({
    handleGetPath: "",
    handleInsertPath: "",
    handleUpdatePath: "",
    handleDownLoadPath: "",
    handleData: {},
  });
  export default {
    mixins: [mPage, mHandle],
    components:{chartOne,VueAliplayerV2},
    data() {
      let { searchItems,tableColumns } = Configs(this);
      return {
        searchItems,
        tableColumns,
        dialogVisible: false,
        title:'',
        pageData:[],
        sensorList:[],
        sensorDetailList:[],
        videoList:[],
        isAdd:true,
        chartData: {
          legend:[],
          markLineList:[]
        },
        loading:false,
        video:{url:''},
        options: {
          height: "100%",
          isLive: true,
          format: "m3u8",
          autoplay: true,
          skinLayout: false
        },
      };
    },
    created() {
      this.getSensor()
      this.getVideoList()
    },
    methods: {
      handleReady() {
        this.$refs.videoPlayer.play();
      },
      getSensor(){
        this.$http.get("/sensorAnalysis/sensorType/list").then(res => {
          if(res.success){
            if (res.data){
              this.sensorList = res.data
              this.searchItems[0].options = []
              for (let item of this.sensorList){
                this.searchItems[0].options.push({label: item.name, value: item.id})
              }
            }
          }
        })
      },
      getSensorDetail(id,type){
        if (!id){
          this.pageParams.pointId = ''
          this.searchItems[1].options = []
          this.sensorDetailList = []
          return
        }
        this.$http.get("/sensorAnalysis/sensorDetail/list",{params:{id:id}}).then(res => {
          if(res.success){
            if (res.data){
              this.sensorDetailList = res.data
              if (type){
                this.searchItems[1].options = []
                for (let item of this.sensorDetailList){
                  this.searchItems[1].options.push({label: item.subName, value: item.id})
                }
              }

            }
          }
        })
      },
      getVideoList(){
        this.$http.get("/intelligentCapture/list/video").then(res => {
          if(res.success){
            if (res.data){
              this.videoList = res.data
              this.searchItems[2].options = []
              for (let item of this.videoList){
                this.searchItems[2].options.push({label: item.channelName, value: item.id})
              }
            }
          }
        })
      },
      beforeDialogClose(done){
        done()
      },
      gotoDetail(id){
        this.loading = true
        this.$http.get('/intelligentCapture/warn/detail',{params:{id:id}}).then(res => {
          if (res.success){
            if (res.data){
              res.data.colorList = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
              this.chartData = res.data
            }
            this.dialogVisible = true
          } else {
            this.$message.error(res.mag)
          }
          this.loading = false
        })
      }
    },
  };
</script>

<style scoped>
  .form-flex{
    display: flex;
  }
  .form-item-btn{
    width: 100px;
    margin-left: 10px;
  }
  /deep/ .el-dialog__body{
    padding: 10px 20px 30px;
  }
  .detail span{
    margin-right: 10px;
  }
  .health-chart{
    width: 50%;
    height: 100%;
  }
  .res-video{
    width: 45%;
    height: 100%;
    background: #000;
  }
</style>