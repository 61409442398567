<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
  import * as echarts from "echarts";
  import { on, off } from "@/libs/tools";
  export default {
    name: "ChartBar",
    props: {
      value: Object,
      text: String,
      subtext: String,
    },
    data() {
      return {

      };
    },
    watch: {
      value: {
        handler(val) {
          this.loadEcharts(val);
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {
      // this.loadEcharts();
    },
    methods: {
      resize() {
        this.dom.resize();
      },
      loadEcharts(val) {
        let self = this;
        let markLine = []
        if (val.markLineList.length > 0){
          for(let i =0;i < val.markLineList.length;i++){
            let color = ''
            if (val.markLineList[i].name.substr(0,4) === '一级阈值'){
              color = '#61A0A8'
            } else if (val.markLineList[i].name.substr(0,4) === '二级阈值'){
              color = '#C23531'
            } else if (val.markLineList[i].name.substr(0,4) === '三级阈值'){
              color = '#CA8622'
            }
            markLine.push({
              name: val.markLineList[i].name,
              yAxis: val.markLineList[i].data,
              lineStyle:{
                type:'solid',
                color:color,
                cap:'butt'
              },
            });
          }
        }
        this.$nextTick(() => {
          self.xAxisData = val.xaxis;
          self.series = [val.yaxis];
          // self.yAxisName = val.unit
          let option = {
            tooltip: {
              trigger: "axis",
            },
            legend: {
              show: true,
              // right:120,
              top:10,
              itemStyle:{

              },
              // orient:'vertical',
              data:val.legend,
              selected:{}
            },
            grid: {
              top: 50,
              left: 80,
              right: 50,
              bottom: "10%",
            },
            xAxis: [
              {
                boundaryGap: ["2%", "2%"],
                axisLine: {
                  //坐标轴轴线相关设置。数学上的x轴
                  show: false,
                  lineStyle: {
                    color: "#C3C3C3",
                  },
                },
                axisLabel: {
                  //坐标轴刻度标签的相关设置
                  color: "#5a5a5a",
                  fontSize: 14,
                  showMaxLabel: true, //强制显示最后一个数据的刻度
                },
                splitLine: {
                  show: false,
                  lineStyle: {
                    type: "dashed",
                    color: "#4CC6FF",
                  },
                },
                axisTick: {
                  show: true,
                },
                data: self.xAxisData,
              },
            ],
            yAxis: [
              {
                name: '',
                nameTextStyle: {
                  color: "#333",
                },
                min: val.ymin,
                max: val.ymax,
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: "#eaeaea",
                  },
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#C3C3C3",
                  },
                },
                axisLabel: {
                  show: true,
                  fontSize: 14,
                  color: "#5a5a5a",
                  padding: 5,
                  formatter: function (value) {
                    if (value === 0) {
                      return value;
                    }
                    return value;
                  },
                },
                axisTick: {
                  show: false,
                },
              },
            ],
            series: []
          };
          if (self.series){
            if (markLine.length > 0){
              markLine.forEach(item => {
                // option.legend[1].data.push(item.name)
                option.series.push({
                  type:'line',
                  name:item.name,
                  symbol:'none',
                  itemStyle: {
                    color:item.lineStyle.color,
                  },
                  lineStyle:{
                    color:item.lineStyle.color,
                  },
                  markLine: {
                    symbol:'none',
                    data: [item]
                  }
                })
                option.legend.selected[item.name] = false
              });
            }
            for (let i = 0; i < self.series.length;i++){
              option.series.push({
                type:'line',
                name:'原始值',
                data:self.series[i],
                symbol:'none'
              });
            }
            for (let i = 0; i < self.series.length;i++){
              // option.legend.data.push(self.series[i].name)
              if (i === 0){
                option.legend.selected['line'] = true
              }
            }
          }
          if (this.dom != null && this.dom != "" && this.dom != undefined) {
            this.dom.dispose();
          }
          this.dom = echarts.init(this.$refs.dom);
          this.dom.setOption(option);
          on(window, "resize", this.resize);
        });
      },
      randomData(data) {
        let self = this;
        self.now = new Date(+self.now + self.oneDay);
        return {
          name: self.now.toString(),
          value: [self.now, Math.random() * data],
        };
      }
    },

    beforeDestroy() {
      off(window, "resize", this.resize);
    },
  };
</script>
